/* Input Range */
.range-input {
  @apply appearance-none w-full h-3 bg-gray-200 rounded-md outline-none;
}

.range-input::-webkit-slider-thumb {
  @apply appearance-none w-5 h-5 rounded-full bg-indigo-600 cursor-pointer;
}

.range-input::-webkit-slider-thumb:hover {
  @apply bg-indigo-500;
}
